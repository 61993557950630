<script>
import { mapGetters, mapActions } from 'vuex'
import { colord } from 'colord'
import * as Sentry from '@sentry/vue'

import router from './router'
import i18n from './utils/vue-i18n'
import * as storage from './utils/storage'
import ja from './ja'
import { ACCESS_TOKEN_KEY, getAccessToken } from './auth'
import Toasts from './components/Toasts.vue'

export default {
    components: { Toasts },
    data() {
        return {
            lang: 'en',
            scriptsLoaded: false,
            isMobile: false,
            channel: null,
        }
    },

    computed: {
        ...mapGetters({
            authenticated: 'account/authenticated',
            user: 'account/user',
            project: 'project/project',
            colors: 'colors',
        }),

        dev() {
            return import.meta.env.DEV
        },

        base_url() {
            return import.meta.env.VITE_BASE_URL
        },
        backend_url() {
            return import.meta.env.VITE_BACKEND_URI
        },

        /**
         * Return true if customer's language is english.
         * @return {boolean}
         */
        is_english() {
            return this.lang === 'en'
        },

        is_japanese() {
            return this.lang === 'ja'
        },

        locale() {
            switch (this.lang) {
                case 'ja':
                    return 'ja-jp'
                default:
                    return 'en-us'
            }
        },

        is_dark_mode() {
            return (
                !this.is_ssr &&
                window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: dark)').matches
            )
        },
        store() {
            return this.$store.state.store
        },
        currency() {
            return this.store?.currency || 'jpy'
        },
        project_link() {
            return this.project?.link || ''
        },
        date_format() {
            return 'yyyy/MM/dd'
        },
        date_format_with_time() {
            return 'yyyy/MM/dd HH:mm'
        },
    },
    watch: {
        colors: {
            immediate: true,
            deep: true,
            handler() {
                if (this.is_ssr) return

                document.documentElement.style.setProperty(
                    '--color-brand',

                    this.colors.primary
                )
                document.documentElement.style.setProperty(
                    '--color-brand--hover',
                    colord(this.colors.primary).saturate(0.18).darken(0.1).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-primary--background',
                    colord(this.colors.primary).desaturate(0.45).lighten(0.5).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-primary--text',
                    colord(this.colors.primary).desaturate(0.1).darken(0.77).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-primary--graphic',
                    colord(this.colors.primary).desaturate(0.2).darken(0.3).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-primary--line',
                    colord(this.colors.primary).desaturate(0.33).lighten(0.44).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-primary--border',
                    colord(this.colors.primary).desaturate(0.38).lighten(0.34).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-secondary--background',
                    colord(this.colors.primary).desaturate(0.4).darken(0.04).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-secondary--text',
                    colord(this.colors.primary).desaturate(0.38).darken(0.1).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-secondary--graphic',
                    colord(this.colors.primary).desaturate(0.32).lighten(0.46).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-indigo-light--background',
                    colord(this.colors.primary).lighten(0.49).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-indigo-light--hover',
                    colord(this.colors.primary).lighten(0.44).toHex()
                )

                // Pass colors
                document.documentElement.style.setProperty(
                    '--color-pass--active',
                    colord(this.colors.active_pass).toHex()
                )
                // Text colors
                document.documentElement.style.setProperty(
                    '--color-link',
                    colord(this.colors.text_links).toHex()
                )
                document.documentElement.style.setProperty(
                    '--color-link--hover',
                    colord(this.colors.text_links).saturate(0.18).darken(0.1).toHex()
                )
            },
        },
        project(value) {
            if (!value) return
            if (this.scriptsLoaded) return
            if (!this.store) return

            let script = null
            if (this.store.connect_id) {
                script = 'https://js.stripe.com/v3/'
                Sentry.setTag('payment', 'Stripe')
            } else if (this.store.merchant_id) {
                Sentry.setTag('payment', 'Squareup' + (this.dev ? ' sandbox' : ''))
                script = this.dev
                    ? 'https://sandbox.web.squarecdn.com/v1/square.js'
                    : 'https://web.squarecdn.com/v1/square.js'
            } else {
                Sentry.setTag('payment', 'None')
            }
            script && this.loadScript(script)
            if (this.project?.with_face_photo) {
                this.loadScript('/js/pico.js')
            }
            this.scriptsLoaded = true
        },
        lang(value) {
            this.changeLanguage(value)
        },
        'user.language'() {
            if (!['ja', 'en'].includes(this.user?.language)) return

            const userLang = this.user?.language

            if (userLang && userLang !== this.lang) this.setLanguage(userLang)
        },
        '$route.query.lang': {
            immediate: true,
            handler() {
                if (['ja', 'en'].includes(this.$route.query.lang)) {
                    this.lang = this.$route.query.lang
                }
            },
        },
        authenticated(value) {
            if (value) {
                this.$echo.connector.options.auth.headers['X-Auth-Token'] = getAccessToken()
                this.channel = this.$echo.join('store')
            } else {
                this.$echo.leave(this.channel)
            }
        },
    },

    created() {
        this.addTokenHook()
        i18n.init({ ja })
    },

    mounted() {
        if (this.is_ssr) return
        const defaultLang = /^ja\b/.test(navigator.language) ? 'ja' : 'en'
        this.setLanguage(storage.local.getItem('app.language') || defaultLang)
        storage.local.setItem('app.language', this.lang)
        if (!this.dev) {
            var _mtm = (window._mtm = window._mtm || [])
            _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
            var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0]
            g.type = 'text/javascript'
            g.async = true
            g.src = 'https://a.kinchaku.com/js/container_BVrhshZU.js'
            s.parentNode.insertBefore(g, s)
        }

        window.addEventListener('resize', this.checkIsMobile)
        this.checkIsMobile()
    },

    unmounted() {
        window.removeEventListener('resize', this.checkIsMobile)
    },

    methods: {
        ...mapActions({
            updateLanguage: 'account/updateLanguage',
            addToast: 'addToast',
            handleError: 'handleError',
        }),

        setLanguage(lang) {
            if (lang === undefined) return
            this.lang = lang
            storage.local.setItem('app.language', lang)
        },

        async changeLanguage(lang) {
            this.setLanguage(lang)
            if (this.authenticated && lang != this.user.language) {
                try {
                    await this.updateLanguage(lang)
                } catch (e) {
                    this.handleError(e)
                }
            }
            this.user.language = lang
            this.$store.commit('account/SET_USER', this.user)
        },

        loadScript(script) {
            if (this.is_ssr) return
            const plugin = document.createElement('script')
            plugin.setAttribute('src', script)
            plugin.async = true
            document.head.appendChild(plugin)
        },

        addTokenHook() {
            if (this.is_ssr) return
            window.addEventListener(
                'storage',
                e => {
                    if (e.key == 'reloadUser') {
                        this.$store
                            .dispatch('account/me', this.project?.id)
                            .catch(this._handleError)
                    }
                    if (e.key == ACCESS_TOKEN_KEY) {
                        if (!e.newValue) {
                            this._clearSession()
                        } else {
                            this.$store
                                .dispatch('account/me', this.project?.id)
                                .then(() => {
                                    if (router.currentRoute.path !== this.project.link) {
                                        router.push(this.project.link)
                                    }
                                })
                                .catch(this._handleError)
                        }
                    }
                },
                false
            )
        },

        _clearSession() {
            this.$store.dispatch('account/clearSession')

            if (this.project?.link) {
                router.replace(this.project.link)
            } else {
                router.replace('/signin')
            }
        },
        // used in resize event
        checkIsMobile() {
            this.isMobile = window.innerWidth < 768
        },

        getKeyByLang(object, key) {
            if (this.lang === 'en') {
                return object?.[`${key}_en`] || object?.[key] || ''
            }

            return object?.[key] || object?.[`${key}_en`] || ''
        },
    },
}
</script>

<template>
    <router-view />
    <Toasts />
</template>
