import { createApp as createNonSSRApp, createSSRApp } from 'vue'
import * as Sentry from '@sentry/vue'
import capitalize from 'lodash/capitalize'
import vue3GoogleLogin from 'vue3-google-login'
import Echo from 'laravel-echo'
import { VueHeadMixin, createHead } from '@unhead/vue'
import { InferSeoMetaPlugin } from '@unhead/addons'

import Pusher from 'pusher-js'
import './assets/main.scss'
import './assets/app.css'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './utils/vue-i18n'
import formatCurrency from './utils/format-currency'
import formatDate from './utils/date'
import customForm from './plugins/custom-form'
import analytics from './plugins/analytics'
import { getAccessToken } from '@/auth'

const createApp = async () => {
    const isSSR = import.meta.env.SSR || false
    const app = (isSSR ? createSSRApp : createNonSSRApp)(App)

    app.use(store).use(router).use(customForm)
    if (!isSSR) app.use(analytics)

    const head = createHead()
    head.use(InferSeoMetaPlugin())
    app.use(head)
    app.mixin(VueHeadMixin)

    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.MODE,
            release: import.meta.env.VITE_GIT_TAG || 'unknown',
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText:
                        import.meta.env.MODE === 'production' || import.meta.env.MODE === 'prod',
                }),
            ],
        })
    }

    if (import.meta.env.VITE_APP_ENV === 'local') {
        app.config.errorHandler = function (error, vm, info) {
            // eslint-disable-next-line
            console.error(error, vm, info)
        }
    }

    if (import.meta.env.VITE_GOOGLE_CLIENT_ID) {
        app.use(vue3GoogleLogin, {
            clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        })
    }

    function replaceLastChild(el, str) {
        if (!el) return false
        if (el.children.length) return replaceLastChild(el.children[0], str)
        el.innerText = str
        return true
    }
    let echo = null

    app.mixin({
        mounted() {
            if (!isSSR && !echo) {
                window.Pusher = Pusher

                let options = {
                    auth: { headers: { 'X-Auth-Token': getAccessToken() } },
                    authEndpoint: import.meta.env.VITE_BACKEND_URI + '/storefront/ws/auth',
                    key: import.meta.env.VITE_PUSHER_ID,
                    disableStats: true,
                    enabledTransports: ['ws', 'wss'],
                    broadcaster: import.meta.env.VITE_BROADCASTER || 'pusher',
                }
                if (options.broadcaster === 'reverb') {
                    options.wsHost = import.meta.env.VITE_PUSHER_HOST
                } else {
                    options.cluster = 'ap3'
                }
                echo = new Echo(options)
            }
        },
        computed: {
            $echo() {
                return echo || {}
            },
            is_ssr() {
                return isSSR
            },
        },
        methods: {
            $t(key, replacements = {}) {
                return i18n.translate(this.$root.lang, key, replacements)
            },
            $cu(amount, currency) {
                return formatCurrency(amount, currency)
            },
            $d(date, format, locale = this.$root.lang) {
                return formatDate(date, format, locale)
            },
            $c(value) {
                return capitalize(value)
            },
        },
    })

    app.directive('translate', (el, binding) => {
        var translated_substrings = i18n
            .translate(this.$root.lang, binding.value.key, binding.value.replace)
            .split('|')

        var children = el.children

        for (var i = 0; i < children.length; i++) {
            if (translated_substrings[i]) {
                replaceLastChild(children[i], translated_substrings[i])
            }
        }
    })

    // head.push({
    //     htmlAttrs: {
    //         class: 'h-full',
    //         lang: 'ja',
    //         translate: 'no',
    //     },
    //     bodyAttrs: {
    //         class: 'h-full;',
    //     },
    // })

    return { app, router, head }
}

export default createApp
