import * as Sentry from '@sentry/vue'
import { isPast } from 'date-fns'

import { setAccessToken, clearAccessToken } from '@/auth'
import { jsonToFormData } from '@/utils/formdata'
import api from '@/api'

export default {
    namespaced: true,
    state: {
        user: {},
    },
    getters: {
        authenticated(state) {
            return !!state.user?.id
        },
        user(state) {
            return state.user
        },
        contact_image(state) {
            const { user } = state
            if (!user?.contact?.contact_image) return false

            return (
                import.meta.env.VITE_BACKEND_URI +
                `/assets/contact/v2/${user.contact.shortuuid}/image?lastmod=${Date.now()}`
            )
        },
        contact(state) {
            return state.user?.contact
        },
        need_connection_to_workspace(state, getters, rootState, rootGetters) {
            const { authenticated, contact } = getters
            const have_project = rootGetters['project/have_project']

            return authenticated && have_project && !contact?.id
        },
        coupons_list(state) {
            return state.user?.coupons || []
        },
        passes_list(state) {
            const passes = state.user?.items ? state.user?.items : []

            if (state.user?.membership_pass) passes.push(state.user?.membership_pass)

            return passes
        },
        active_passes(state, getters) {
            return getters['passes_list']?.filter(p => !p.is_expired) || []
        },
        card(state) {
            return state.user?.card_last_four
        },
        invoices(state) {
            return state.user?.invoices
        },
        payment_sources(state) {
            return state.user?.payment_sources
        },
        verifications(state, getters) {
            return getters.contact?.verifications || []
        },
        lottery_tickets(state, getters) {
            return getters.contact?.lottery_tickets || []
        },
        last_verification(state, getters) {
            if (!getters['verifications']?.length) return

            return getters['verifications'][getters['verifications']?.length - 1]
        },
        is_kyc_done(state, getters) {
            switch (getters.last_verification?.status) {
                case 'approved':
                    return true
                case 'rejected':
                case 'declined':
                case 'pending':
                default:
                    return false
            }
        },
        is_kyc_pending(state, getters) {
            return getters.last_verification?.status === 'pending'
        },
        is_kyc_rejected(state, getters) {
            return getters.last_verification?.status === 'rejected'
        },
        kyc_notes(state, getters) {
            return getters.last_verification?.notes
        },
        has_won_lottery(state, getters) {
            return getters.lottery_tickets.some(
                ticket => ticket.is_winner && !isPast(ticket.expires_at)
            )
        },
        data_collection_complete(state, getters, rootState, rootGetters) {
            const contact = getters.contact
            const formFields = rootGetters['project/form_fields']

            for (const fieldName in formFields) {
                if (fieldName === 'address') {
                    if (!contact?.address) return false

                    const addressesFields = formFields?.[fieldName]?.fields

                    if (addressesFields) {
                        for (const addressFieldName in addressesFields) {
                            const addressField = addressesFields[addressFieldName]
                            if (!addressField?.show || !addressField?.required) continue
                            if (!contact?.address?.[addressFieldName]) return false
                        }
                    } else if (
                        formFields?.['address']?.enabled &&
                        formFields?.['address']?.required &&
                        !contact?.address
                    ) {
                        return false
                    }
                } else {
                    if (!formFields[fieldName].enabled) continue

                    if (!contact?.[fieldName] && !contact?.meta?.[fieldName]) {
                        if (fieldName === 'picture') {
                            if (!contact?.contact_image) return false
                        } else {
                            return false
                        }
                    }
                }
            }

            return true
        },
    },
    mutations: {
        SET_USER(state, value) {
            state.user = value
            if (typeof window !== 'undefined' && window._paq) {
                window._paq.push(['setUserId', value.id])
            }
            if (import.meta.env.VITE_SENTRY_DSN) {
                Sentry.setUser(
                    value ? { email: value.email, id: value.id, language: value.language } : null
                )
            }
        },
        SET_USER_PASSES(state, items) {
            state.user.items = items
        },
        RESET(state) {
            state.user = {}
        },
    },
    actions: {
        async checkEmail(context, data) {
            const result = await api.post('/check-email', data)

            return result
        },
        async signIn({ dispatch }, user) {
            const result = await api.post('/login', user).then(response => {
                if (response.tfa) {
                    return false
                }
                setAccessToken(response.token)

                return dispatch('me')
            })

            return result.user
        },
        async signOut({ commit }) {
            await api.post('/logout')

            clearAccessToken()
            commit('SET_USER', {})
        },
        async signUp({ commit, rootGetters, dispatch }, user) {
            const project = rootGetters['project/project']
            const campaign = rootGetters['campaign']
            const companyId = rootGetters['company_id']

            if (project) user.project_id = project.id
            if (campaign) user.campaign = campaign
            if (companyId) user.company_id = companyId

            const result = await api.post('/register', user)

            if (result && result.user) {
                commit('SET_USER', result.user)
            }

            if (result && result.token) {
                setAccessToken(result.token)
                await dispatch('me')
            }

            return result
        },
        async me({ commit, rootGetters }, { global } = {}) {
            const project = rootGetters['project/project']

            return api
                .get(project?.id && !global ? `/profile/${project?.id}` : '/profile')
                .then(({ user }) => {
                    commit('SET_USER', user)

                    return user
                })
                .catch(error => {
                    if (error?.response?.status == 429) throw error

                    clearAccessToken()
                    commit('SET_USER', {})
                })
        },
        async oauthCallback({ commit, dispatch, rootGetters }, { service, params }) {
            const project = rootGetters['project/project']
            const campaign = rootGetters['campaign']

            if (project) params.project_id = project.id
            if (campaign) params.campaign = campaign

            const result = await api.post(`/oauth/${service}`, params)

            if (result && result.user) {
                commit('SET_USER', result.user)
            }

            if (result && result.token) {
                setAccessToken(result.token)
                await dispatch('me')
            }

            return result
        },
        async jwtLogin({ commit, dispatch }, { session, slug }) {
            const result = await api.post('/token', { session, slug })

            if (result && result.user) {
                commit('SET_USER', result.user)
            }

            if (result && result.token) {
                setAccessToken(result.token)
                await dispatch('me', { global: true })
            }

            return result
        },
        changePassword(context, data) {
            return api.post('/change-password', data)
        },
        resetPassword(context, data) {
            return api.post('/reset-password', data)
        },
        requestReset(context, email) {
            return api.post('/reset', { email })
        },
        changePicture(context, picture) {
            let data = new FormData()
            data.append('picture', picture)

            return api.request({
                url: '/change-picture',
                method: 'post',
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
                data,
            })
        },
        deletePicture() {
            return api.delete('/delete-picture')
        },
        updateLanguage(context, language) {
            return api.patch('/language', { language })
        },
        updateCustomer({ commit, rootGetters }, { user }) {
            const project = rootGetters['project/project']

            let contentType = 'application/json'
            let data

            if (user.picture || user.face_photo || user.id_document) {
                contentType = 'multipart/form-data'
                data = jsonToFormData(user)
            } else {
                data = user
            }

            return api
                .request({
                    url: `/${project.id}/update`,
                    method: 'post',
                    config: { headers: { 'Content-Type': contentType } },
                    data,
                })
                .then(({ user }) => {
                    commit('SET_USER', user)
                    return data
                })
        },
        updateAccount({ commit }, user) {
            let contentType = 'application/json'
            let data

            if (user.picture || user.face_photo || user.id_document) {
                contentType = 'multipart/form-data'
                data = jsonToFormData(user)
            } else {
                data = user
            }

            return api
                .request({
                    url: '/profile',
                    method: 'post',
                    config: { headers: { 'Content-Type': contentType } },
                    data,
                })
                .then(({ user }) => {
                    commit('SET_USER', user)
                    return data
                })
        },
        clearSession({ commit }) {
            commit('RESET')
        },
        async removeAccount({ commit }) {
            const result = await api.delete('/profile')

            clearAccessToken()
            commit('SET_USER', {})

            return result
        },
        async connectUserToWorkspace({ rootGetters, dispatch }) {
            const project = rootGetters['project/project']
            const campaign = rootGetters['campaign']

            await api.post(`/${project.id}/connect`, { campaign })

            return await dispatch('me')
        },
        async verifyEmail({ dispatch }, { id, token }) {
            await api.post('/verify-email', { id, token })

            return await dispatch('me')
        },
        async resendConfirmationEmail({ rootGetters, dispatch }) {
            const project = rootGetters['project/project']

            await api.post('/resend-email', project ? { project: project.id } : {})

            return await dispatch('me')
        },
    },
}
