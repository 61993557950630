import api from '@/api'

export default {
    namespaced: true,
    actions: {
        // legacy?
        // book({ rootGetters }, data) {
        //     return api.post(`/${rootGetters['project/project'].id}/book`, data)
        // },
        order({ rootGetters }, { data, content_type }) {
            const retryToken = sessionStorage?.getItem('retryToken')

            return api.request({
                url: `/${rootGetters['project/project'].id}/order`,
                method: 'post',
                config: { headers: { 'Content-Type': content_type } },
                data,
                headers: retryToken ? { ['x-retry-token']: retryToken } : {},
            })
        },
        findCoupon({ rootGetters }, { coupon }) {
            return api.post(`/${rootGetters['project/project'].id}/coupons/${coupon}`, {})
        },
        createIntent({ rootGetters }, { cart, payment_method }) {
            return api.post(`/${rootGetters['project/project'].id}/intent`, {
                cart,
                payment_method,
            })
        },
        deleteCard(context, id) {
            return api.delete(`/card/${id}`)
        },
        saveAccountCard(context, { id }) {
            return api.put(`/card/${id}`)
        },
        deleteAccountCard(context, { id }) {
            return api.delete(`/card/${id}`)
        },
    },
}
