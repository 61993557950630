import { createStore } from 'vuex'
import i18n from '@/utils/vue-i18n'

import api from '@/api'
import { hasToken } from '@/auth'
import router from '@/router'

import account from './account'
import project from './project'
import cart from './cart'
import pass from './pass'
import payment from './payment'

export default createStore({
    state: {
        store: {},

        toasts: [],
        preloaded: false,
        full404Error: false,
        full503Error: false,
        fullLoader: false,
        accountLoader: false,
        accessToWorkspaceModal: false,

        retryTimer: 0,
    },
    getters: {
        backend_uri() {
            return import.meta.env.VITE_BACKEND_URI
        },
        is_preloaded(state) {
            return state.preloaded
        },
        is_full_404_error(state) {
            return state.full404Error
        },
        is_full_503_error(state) {
            return state.full503Error
        },
        is_full_loader(state) {
            return state.fullLoader
        },
        is_account_loader(state) {
            return state.accountLoader
        },
        show_access_to_workspace_modal(state) {
            return state.accessToWorkspaceModal
        },
        is_whitelabel_mode(state, { flags }) {
            return !!flags.with_whitelabel
        },
        whitelabel_terms(state, { flags }) {
            return flags.whitelabel_terms || ''
        },
        retry_timer(state) {
            return state.retryTimer
        },
        disable_report_problem(state, { flags }) {
            return flags?.disable_report_problem
        },
        disable_email_auth(state, { flags }) {
            return flags?.disable_email_auth
        },
        enable_email_registration(state) {
            return state.store?.enable_email_registration || true
        },
        is_custom_domain(state) {
            return state.store?.is_custom_domain
        },

        flags(state) {
            return state.store?.flags || {}
        },
        has_stripe(state) {
            return state.store?.connect_id
        },
        has_square(state) {
            return state.store?.merchant_id
        },
        tax_percent(state) {
            return state.store?.taxPercent
        },
        brand_name(state) {
            return state.store?.brand_name
        },
        currency(state) {
            return state.store?.currency || 'jpy'
        },
        terms_url(state) {
            return state.store?.link_to_terms
        },
        privacy_url(state) {
            return state.store?.link_to_privacy
        },
        cancelation_url(state) {
            return state.store?.link_to_cancelation
        },
        logo(state) {
            return state.store?.logo
        },
        campaign(state) {
            return state.store?.campaign
        },
        company_id(state) {
            return state.store?.company_id || null
        },
        show_ios_warning(state) {
            return state.store?.show_ios_warning
        },
        is_ios(state) {
            return state.store?.is_ios
        },
        is_android(state) {
            return state.store?.is_android
        },

        // shorturl
        with_pass_code(state) {
            return state.store?.with_pass_code
        },
        is_shorturl_disabled(state) {
            return !!state.store?.is_shorturl_disabled
        },
        is_shorturl_expired(state) {
            return !!state.store?.is_shorturl_expired
        },
        // end shorturl
        colors(state) {
            return {
                primary: state.store?.colors?.primary || '#3F51B5',
                active_pass: state.store?.colors?.active_pass || '#3F51B5',
                text_links: state.store?.colors?.text_links || '#3F51B5',
            }
        },
    },
    mutations: {
        SET_STORE(state, value) {
            state.store = value
            if (typeof window === 'undefined') return
            document.title = value?.brand_name

            if (value?.throttle?.token) {
                sessionStorage.setItem('retryToken', value.throttle?.token)
            }
        },
        SET_TOAST(state, value) {
            state.toasts = value
        },
        SET_FULL_404_ERROR(state, value) {
            state.full404Error = value
        },
        SET_FULL_503_ERROR(state, value) {
            state.full503Error = value
        },
        SET_FULL_LOADER(state, value) {
            state.fullLoader = value
        },
        SET_ACCOUNT_LOADER(state, value) {
            state.accountLoader = value
        },
        SET_ACCESS_TO_WORKSPACE_MODAL(state, value) {
            state.accessToWorkspaceModal = value
        },
        SET_PRELOADED(state, value) {
            state.preloaded = value
        },
        SET_RETRY_TIMER(state, value) {
            state.retryTimer = value
        },
    },
    actions: {
        loadStore({ commit, dispatch }, { slug, campaign }) {
            const retryToken =
                typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('retryToken') : null

            return api
                .get(slug ? `/store/${slug}` : '/store', {
                    params: { kc: campaign },
                    headers: retryToken ? { ['x-retry-token']: retryToken } : {},
                })
                .then(({ store }) => {
                    dispatch('setStore', store)

                    if (hasToken() && store?.customer) {
                        commit('account/SET_USER', store.customer, { root: true })
                    }

                    return store
                })
        },
        setStore({ commit, dispatch }, store) {
            if (store?.throttle) {
                sessionStorage.setItem('retryToken', store.throttle?.token)
                dispatch('startRetryTimer', store.throttle?.retry, { root: true })
            }

            commit('SET_STORE', store)
        },
        showFull404Error({ commit }) {
            commit('SET_FULL_404_ERROR', true)
        },
        hideFull404Error({ commit }) {
            commit('SET_FULL_404_ERROR', false)
        },
        showFull503Error({ commit }) {
            commit('SET_FULL_503_ERROR', true)
        },
        showFullLoader({ commit }) {
            commit('SET_FULL_LOADER', true)
        },
        hideFullLoader({ commit }) {
            commit('SET_FULL_LOADER', false)
        },
        showAccountLoader({ commit }) {
            commit('SET_ACCOUNT_LOADER', true)
        },
        hideAccountLoader({ commit }) {
            commit('SET_ACCOUNT_LOADER', false)
        },
        showAccessToWorkspaceModal({ commit }) {
            commit('SET_ACCESS_TO_WORKSPACE_MODAL', true)
        },
        hideAccessToWorkspaceModal({ commit }) {
            commit('SET_ACCESS_TO_WORKSPACE_MODAL', false)
        },
        handleError({ dispatch, rootGetters }, error) {
            const status = error?.response?.status
            const form = error?.form
            const lang = rootGetters['account/user']?.language || 'en'

            if (
                status == 422 &&
                form &&
                Object.keys(error.response?.data?.errors || {}).length > 0 &&
                typeof Object.values(error.response?.data?.errors)[0] === 'object'
            ) {
                for (const errorField in error.response?.data?.errors) {
                    if (form.fields.includes(errorField)) {
                        form.setFieldsErrors({
                            [errorField]: error.response?.data?.errors[errorField],
                        })
                    } else {
                        dispatch('addToast', {
                            type: 'error',
                            message: Array.isArray(error.response?.data?.errors[errorField])
                                ? error.response?.data?.errors[errorField]
                                      .map(e => i18n.translate(lang, e))
                                      .join(', ')
                                : i18n.translate(lang, error.response?.data?.errors[errorField]),
                        })
                    }
                }

                return
            }

            if (typeof error !== 'string') {
                if (error == 401) {
                    error = 'Access Denied'
                    dispatch('addToast', {
                        type: 'error',
                        message: i18n.translate(lang, error),
                    })
                    return dispatch('goToSignIn', undefined, { root: true })
                } else if (error.errors && error.errors.length) {
                    error = error.errors[0].message || error.errors[0]
                } else if (!error.response) {
                    // eslint-disable-next-line
                    console.error(error)
                    error = 'Request failed'
                } else if (error.response && (status == 401 || status == 403)) {
                    error = 'Access Denied'
                } else if (status == 429) {
                    return dispatch('startRetryTimer', 60)
                } else if (
                    error.response &&
                    error.response.data.errors &&
                    error.response.data.errors.message
                ) {
                    error = error.response.data.errors.message
                } else if (
                    // stripe-style errors
                    error.response &&
                    error.response.data.error &&
                    error.response.data.error.message
                ) {
                    error = error.response.data.error.message
                } else if (error.response && error.response.data.errors) {
                    var errorString = ''
                    for (var prop in error.response.data.errors) {
                        errorString += error.response.data.errors[prop] + '\n'
                    }
                    error = errorString
                } else if (error.response && error.response.data.message) {
                    error = error.response.data.message
                } else if (error?.response?.data?.errorMessage) {
                    error = error.response.data.errorMessage
                } else {
                    error = error.message || 'Request failed'
                }
            }

            dispatch('addToast', { type: 'error', message: i18n.translate(lang, error) })
        },
        startRetryTimer({ commit, state }, value) {
            commit('SET_RETRY_TIMER', value)

            const interval = setInterval(() => {
                commit('SET_RETRY_TIMER', state.retryTimer - 1)

                if (state.retryTimer === 0) clearInterval(interval)
            }, 1000)
        },
        addToast({ commit, state, dispatch }, toast) {
            dispatch('removeToast', toast)

            setTimeout(() => {
                toast.timeout = setTimeout(
                    () => dispatch('removeToast', toast),
                    toast.timer || 10000
                )

                commit('SET_TOAST', [...state.toasts, toast])
            }, [300])
        },
        removeToast({ commit, state }, toast) {
            commit(
                'SET_TOAST',
                state.toasts.filter(item => {
                    const isSameToast = compareToasts(item, toast)

                    if (isSameToast && item.timeout) clearTimeout(item.timeout)

                    return !isSameToast
                })
            )
        },
        sendFeedback({ rootGetters }, feedbackData) {
            const formData = new FormData()
            const user = rootGetters['account/user']
            const project = rootGetters['project/project']

            formData.append('source', 'storefront')
            formData.append('type', feedbackData.type)
            formData.append('message', feedbackData.message)
            formData.append('project_id', project?.id)
            formData.append(
                'viewport[width]',
                Math.max(document.documentElement.clientWidth, window.innerWidth || 0).toString()
            )
            formData.append(
                'viewport[height]',
                Math.max(document.documentElement.clientHeight, window.innerHeight || 0).toString()
            )
            if (user?.id) {
                Object.keys(user).forEach(key =>
                    formData.append(`viewport[user][${key}]`, user[key])
                )
            } else {
                formData.append(`viewport[user][email]`, feedbackData.email)
            }
            if (feedbackData.screenshot) {
                formData.append('screenshot', feedbackData.screenshot)
            }

            return api.request({
                url: `${import.meta.env.VITE_BASE_URL}/kustomer-api/feedback`,
                method: 'post',
                config: { headers: { 'Content-Type': 'multipart/form-data' } },
                data: formData,
            })
        },
        goBack({ dispatch }) {
            if (router.options.history.state.back) {
                router.go(-1)
            } else {
                dispatch('goToIndexPage')
            }
        },
        goToIndexPage({ rootGetters }) {
            if (rootGetters['project/slug']) {
                router.replace({
                    name: 'projectIndexPage',
                    params: { slug: rootGetters['project/slug'] },
                })
            } else {
                router.replace({ name: 'indexPage' })
            }
        },
        goToSignIn({ rootGetters }) {
            if (rootGetters['project/slug']) {
                router.replace({
                    name: 'projectSignInPage',
                    params: { slug: rootGetters['project/slug'] },
                })
            } else {
                router.replace({ name: 'signInPage' })
            }
        },
    },
    modules: { account, project, cart, pass, payment },
})

const compareToasts = (a, b) => {
    return a.type === b.type && a.title === b.title && a.message === b.message
}
